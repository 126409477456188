import React, { Fragment } from 'react';
import {
    CardBody, Button,
} from 'reactstrap';
import PageTitle from 'Layout/Components/PageTitle';
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getAccessToken } from 'context/AuthContext';
import User from 'context/User';

class PaymentStripe extends React.Component {
    constructor(props) {
        super(props);
    }   

    render() {
        return (
            <Fragment>
                <PageTitle
                    heading="Payments Invoice"
                    icon="pe-7s-glasses icon-gradient bg-primary"
                />
                <CardBody>
                </CardBody>
            </Fragment>)
    }
}

export default PaymentStripe;