import React from "react";
import { withTranslation } from "react-i18next";
import { Container, Row, Col, Button } from "reactstrap";

const ScannerApiDocumentation = ({ t }) => {
  return (
    <Container fluid className="p-0" style={{ height: "100vh", overflow: "hidden" }}>
      <Row noGutters className="h-100">
        <Col className="d-flex flex-column justify-content-center align-items-center">
          <h2>{t("scanner_api.documentation.title")}</h2>
          {/* <p>{t("scanner_api.documentation.csp_message")}</p> */}
          <Button
            color="primary"
            href="https://everitas.stoplight.io/docs/eVeritas-phish-guard-api/z3s6d0hhwf0x0-getting-started"
            target="_blank"
          >
            {t("scanner_api.documentation.open_docs")}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default withTranslation()(ScannerApiDocumentation);
