import React, { Fragment } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

// import { Services } from './System';
import Organizations, { Organization } from './Organizations';
import Admins from './Admins';
import BlogPosts from './Blog';
import Ads from './Ads';
import { PaymentsReport } from './Payments';
import PhishingTest from './PhishingTest';
import Campaigns, {
  Campaign, CreateCampaign,
  VisualizeCampaignsCtx, TargetCampaigns
} from './Campaigns';
import ContactMessages from './ContactMessages';
import { NavItems } from './ServicesNav';
import { RegexTemplates } from './DLP';
import { ReportedUrls}  from './ReportedUrls';
import { Category, Threats } from './Catalog';
import { History } from './History';
import { Statistics } from './Statistics';
import { PaymentStripe, Subscriptions } from './PaymentsStripe';

import Partners, { Partner } from './Partners';

import Header from '../Layout/Header';
import AppSidebar from '../Layout/AppSidebar';
import ThemeOptions from '../Layout/ThemeOptions';
import { Gateways } from './Gateways';
import ErrorBoundary from 'ErrorBoundary';
import Accounts from './OutlookAccounts';

export default function ServicesApp() {

  let { path } = useRouteMatch();

  return (
    <Fragment>
      <ThemeOptions />
      <Header />
      <div className="app-main">
        <AppSidebar navigation={NavItems} />
        <div className="app-main__outer">
          <div className="app-main__inner"><ErrorBoundary>
            {/* <Route exact path={`${path}/services`} component={Services} /> */}
            <Route exact path={`${path}/organizations`} component={Organizations} />
            <Route path={`${path}/organization/:domain`} component={Organization} />
            <Route exact path={`${path}/organization`} component={Organization} />
            <Route exact path={`${path}/admins`} component={Admins} />
            <Route exact path={`${path}/messages`} component={ContactMessages} />
            <Route exact path={`${path}/blog`} component={BlogPosts} />
            <Route exact path={`${path}/ads`} component={Ads} />
            <Route exact path={`${path}/paymentsreport`} component={PaymentsReport} />
            <Route exact path={`${path}/phishingtest`} component={PhishingTest} />
            <Route exact path={`${path}/campaigns`} component={Campaigns} />
            <Route path={`${path}/campaign/:campaignId`} component={Campaign} />
            <Route exact path={`${path}/campaign`} component={Campaign} />
            <Route exact path={`${path}/campaigncreate`} component={CreateCampaign} />
            <Route exact path={`${path}/campaignsvisualize`} component={VisualizeCampaignsCtx} />
            <Route exact path={`${path}/campaigntargets`} component={TargetCampaigns} />
            <Route exact path={`${path}/dlpregexes`} component={RegexTemplates} />
            <Route exact path={`${path}/partners`} component={Partners} />
            <Route exact path={`${path}/reported`} component={ReportedUrls} />
            <Route exact path={`${path}/threats`} component={Threats} />
            <Route exact path={`${path}/category`} component={Category} />
            <Route exact path={`${path}/partner`} component={Partner} />
            <Route path={`${path}/partner/:domain`} component={Partner} />
            <Route exact path={`${path}/gateways`} component={Gateways} />
            <Route exact path={`${path}/outlookaccounts`} component={Accounts} />
            <Route exact path={`${path}/history`} component={History} />
            <Route exact path={`${path}/statistics`} component={Statistics} />            
            <Route exact path={`${path}/stripepayment`} component={PaymentStripe} />            
            <Route exact path={`${path}/stripesubs`} component={Subscriptions} />                        
          </ErrorBoundary></div>
        </div>
      </div>
    </Fragment>
  )
}
