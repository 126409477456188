import { freeSubscription, getPaymentList } from "API/ScannerAPI";
import React from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";

class ScannerApiPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      payments: [],
      currentPage: 1,
      pageSize: 10,
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchPayments();
  }

  async fetchPayments() {
    this.setState({ loading: true });
    try {
      // 1) Get payment data
      let response = await getPaymentList();
      let rawPays = response.data || [];

      // 2) If no payments, call freeSubscription & re-fetch
      if (rawPays.length === 0) {
        await freeSubscription();
        response = await getPaymentList();
        rawPays = response.data || [];
      }

      // 3) Transform to your table shape
      const payments = rawPays.map((item) => ({
        invoiceId: item.id, // table expects invoiceId
        description: item.plantype || "", // table expects description
        credits: item.credits || 0, // table expects credits
        paymentDate: item.payment_date || "", // table expects paymentDate
        price: item.amount_total || 0, // table expects price
        status: item.status || "", // table expects status
        invoiceFile: "N/A", // or any fallback if not returned
      }));

      this.setState({ payments });
    } catch (error) {
      console.error("Error fetching payments:", error);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleSearch = (e) => {
    this.setState({ searchTerm: e.target.value, currentPage: 1 });
  };

  nextPage = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + 1,
    }));
  };

  prevPage = () => {
    this.setState((prevState) => ({
      currentPage: Math.max(prevState.currentPage - 1, 1),
    }));
  };

  render() {
    const { t } = this.props;
    const { payments, searchTerm, currentPage, pageSize, loading } = this.state;

    // Filter payments by search term (checking invoiceId + description)
    const filteredPayments = payments.filter((pay) => {
      const invoiceMatch =
        pay.invoiceId &&
        pay.invoiceId.toLowerCase().includes(searchTerm.toLowerCase());
      const descMatch =
        pay.description &&
        pay.description.toLowerCase().includes(searchTerm.toLowerCase());
      return invoiceMatch || descMatch;
    });

    const totalFiltered = filteredPayments.length;
    const startIndex = (currentPage - 1) * pageSize;
    const pageData = filteredPayments.slice(startIndex, startIndex + pageSize);
    const totalPages = Math.ceil(totalFiltered / pageSize);

    return (
      <div className="scanner-payments-page">
        <div className="payments-header">
          <h2>{t("scanner_api.payments.title")}</h2>
          <p>{t("scanner_api.payments.subtitle")}</p>
        </div>
        <Card>
          <CardBody>
            {loading ? (
              <div style={{ textAlign: "center", margin: "2rem 0" }}>
                <Spinner color="primary" />
              </div>
            ) : (
              <>
                <Row className="mb-2">
                  <Col md="6">
                    <strong>{t("scanner_api.payments.table.invoices")}</strong>
                  </Col>
                  <Col md="6" className="text-right">
                    <Input
                      type="text"
                      value={searchTerm}
                      onChange={this.handleSearch}
                      placeholder={t("scanner_api.payments.table.search")}
                      style={{ display: "inline-block", width: "200px" }}
                    />
                  </Col>
                </Row>
                <Table hover responsive>
                  <thead>
                    <tr>
                      <th>{t("scanner_api.payments.table.invoices")}</th>
                      <th>{t("scanner_api.payments.table.description")}</th>
                      <th>{t("scanner_api.payments.table.credits_quota")}</th>
                      <th>{t("scanner_api.payments.table.payment_date")}</th>
                      <th>{t("scanner_api.payments.table.price")}</th>
                      <th>{t("scanner_api.payments.table.payment_status")}</th>
                      <th>{t("scanner_api.payments.table.invoice_file")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageData.map((pay, idx) => (
                      <tr key={idx}>
                        <td>{pay.invoiceId}</td>
                        <td>{pay.description}</td>
                        <td>{pay.credits}</td>
                        <td>{pay.paymentDate}</td>
                        <td>{Number(pay.price).toFixed(2)}</td>
                        <td>{pay.status}</td>
                        <td>{pay.invoiceFile}</td>
                      </tr>
                    ))}
                    {pageData.length === 0 && (
                      <tr>
                        <td colSpan="7" className="text-center">
                          {t("scanner_api.payments.table.no_results")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    {t("scanner_api.payments.table.showing_entries", {
                      from: startIndex + 1,
                      to: startIndex + pageData.length,
                      total: totalFiltered,
                    })}
                  </span>
                  <div>
                    <Button
                      color="light"
                      size="sm"
                      onClick={this.prevPage}
                      disabled={currentPage === 1}
                      className="mr-1"
                    >
                      {t("scanner_api.payments.table.previous")}
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      onClick={this.nextPage}
                      disabled={currentPage >= totalPages}
                    >
                      {t("scanner_api.payments.table.next")}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation()(ScannerApiPayments);
