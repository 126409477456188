import { freeSubscription, getSubscription } from "API/ScannerAPI";
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";

class ScannerApiSubscriptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      subscriptions: [],
      currentPage: 1,
      pageSize: 10,
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchSubscriptions();
  }

  async fetchSubscriptions() {
    this.setState({ loading: true });
    try {
      // 1) Get subscription data from API
      let response = await getSubscription();
      let rawSubs = response.data || [];

      // 2) If empty => create free subscription => re-fetch
      if (rawSubs.length === 0) {
        await freeSubscription();
        response = await getSubscription();
        rawSubs = response.data || [];
      }

      // 3) Transform the data to match your table shape
      const subscriptions = rawSubs.map((item) => ({
        // Table references sub.invoiceId
        invoiceId: item.id,
        // Table references sub.description
        description: item.plantype || "",
        // Table references sub.credits
        credits: item.credits || 0,
        // Table references sub.renewalDate
        renewalDate: item.paymentexpiredate,
        // Table references sub.price
        price: item.amount_total || 0,
        // Table references sub.status
        status: item.status || "",
      }));

      this.setState({ subscriptions });
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    } finally {
      this.setState({ loading: false });
    }
  }

  handleSearch = (e) => {
    this.setState({ searchTerm: e.target.value, currentPage: 1 });
  };

  nextPage = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + 1,
    }));
  };

  prevPage = () => {
    this.setState((prevState) => ({
      currentPage: Math.max(prevState.currentPage - 1, 1),
    }));
  };

  render() {
    const { t } = this.props;
    const { subscriptions, searchTerm, currentPage, pageSize, loading } =
      this.state;

    // Filter subscriptions by search term
    const filteredSubscriptions = subscriptions.filter(
      (item) =>
        (item.invoiceId && item.invoiceId.includes(searchTerm)) ||
        (item.description &&
          item.description.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const totalFiltered = filteredSubscriptions.length;
    const startIndex = (currentPage - 1) * pageSize;
    const pageData = filteredSubscriptions.slice(
      startIndex,
      startIndex + pageSize
    );

    return (
      <div className="scanner-subscriptions-page">
        <h2 className="page-heading">{t("scanner_api.subscriptions.title")}</h2>
        <div className="subscriptions-banner">
          <span className="banner-text">
            {t("scanner_api.subscriptions.banner_text")}
          </span>
          <div className="banner-buttons">
            <Link to="/organization/scanner_api/buy">
              <Button color="primary" className="mr-2">
                {t("scanner_api.subscriptions.banner_buy_more")}
              </Button>
            </Link>
            <Button color="warning" className="text-dark">
              {t("scanner_api.subscriptions.banner_update_payment")}
            </Button>
          </div>
        </div>
        <Card>
          <CardBody>
            {loading ? (
              <div style={{ textAlign: "center", margin: "2rem 0" }}>
                <Spinner color="primary" />
              </div>
            ) : (
              <>
                <Row className="mb-2">
                  <Col md="6">
                    <strong>
                      {t("scanner_api.subscriptions.table.invoices")}
                    </strong>
                  </Col>
                  <Col md="6" className="text-right">
                    <Input
                      type="text"
                      value={searchTerm}
                      onChange={this.handleSearch}
                      placeholder={t("scanner_api.subscriptions.table.search")}
                      style={{ display: "inline-block", width: "200px" }}
                    />
                  </Col>
                </Row>
                <Table hover responsive>
                  <thead>
                    <tr>
                      <th>{t("scanner_api.subscriptions.table.invoices")}</th>
                      <th>
                        {t("scanner_api.subscriptions.table.description")}
                      </th>
                      <th>{t("scanner_api.subscriptions.table.credits")}</th>
                      <th>
                        {t("scanner_api.subscriptions.table.next_renewal_date")}
                      </th>
                      <th>{t("scanner_api.subscriptions.table.price")}</th>
                      <th>{t("scanner_api.subscriptions.table.action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageData.map((sub, idx) => (
                      <tr key={idx}>
                        <td>{sub.invoiceId}</td>
                        <td>{sub.description}</td>
                        <td>{sub.credits}</td>
                        <td>{sub.renewalDate}</td>
                        <td>{Number(sub.price).toFixed(2)}</td>
                        <td>{sub.status}</td>
                      </tr>
                    ))}
                    {pageData.length === 0 && (
                      <tr>
                        <td colSpan="6" className="text-center">
                          {t("scanner_api.subscriptions.table.no_results")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-between align-items-center">
                  <span>
                    {t("scanner_api.subscriptions.table.showing_entries", {
                      from: startIndex + 1,
                      to: startIndex + pageData.length,
                      total: totalFiltered,
                    })}
                  </span>
                  <div>
                    <Button
                      color="light"
                      size="sm"
                      onClick={this.prevPage}
                      disabled={this.state.currentPage <= 1}
                      className="mr-1"
                    >
                      {t("scanner_api.subscriptions.table.previous")}
                    </Button>
                    <Button
                      color="light"
                      size="sm"
                      onClick={this.nextPage}
                      disabled={
                        this.state.currentPage >=
                        Math.ceil(totalFiltered / pageSize)
                      }
                    >
                      {t("scanner_api.subscriptions.table.next")}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withTranslation()(ScannerApiSubscriptions);
