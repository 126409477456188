import { createStripePayment } from "API/ScannerAPI";
import React from "react";
import { withTranslation } from "react-i18next";
import { FaInfoCircle } from "react-icons/fa";
import { IoIosCard } from "react-icons/io";
import ReactTable from "react-table";
import { Button, CardBody, Spinner, Tooltip } from "reactstrap";

class ScannerBuyContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsList: this.props.optionsList,
      tooltipOpen: false,
    };
    this.tooltipId = `Tooltip-${Math.random().toString(36).substr(2, 9)}`; // Generate a unique ID for each tooltip
  }

  toggleTooltip = () => {
    this.setState((prevState) => ({
      tooltipOpen: !prevState.tooltipOpen,
    }));
  };

  async buy(plan) {
    const params = { productId: plan.id };
    const response = await createStripePayment(params);

    if (response.ok) {
      window.open(response.url, "_blank").focus();
    } else {
      alert("Error processing payment.");
    }
  }

  render() {
    const {
      title,
      subTitle,
      product,
      tooltip,
      buyText,
      optionsList,
      mostPopular,
    } = this.props;
    const { t, i18n } = this.props;

    return (
      <div className="bg-white rounded shadow-sm">
        <CardBody className="position-relative overflow-hidden">
          <div>
            <div
              className="d-flex align-items-center"
              style={{ gap: "0.5rem" }}
            >
              <h2>{title}</h2>
              <FaInfoCircle id={this.tooltipId} className="text-lg" />
              <Tooltip
                isOpen={this.state.tooltipOpen}
                target={this.tooltipId}
                toggle={this.toggleTooltip}
              >
                {tooltip}
              </Tooltip>
            </div>
            <span className="text-muted">{subTitle}</span>
            {mostPopular && (
              <div
                className="position-absolute"
                style={{
                  top: "2rem",
                  right: "-2rem",
                  width: "150px",
                  transform: "rotate(45deg)",
                  background: "#28a745",
                  color: "white",
                  textAlign: "center",
                  fontSize: "10px",
                  fontWeight: "bold",
                  padding: "3px 0",
                }}
              >
                {mostPopular}
              </div>
            )}
          </div>
          {optionsList.length > 0 ? (
            <ReactTable
              columns={[
                {
                  id: "queries",
                  Cell: (row) => (
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="flex-fill text-center">
                        {row.original.queriesPerDay}
                      </div>
                      <div className="flex-fill text-center">{product}</div>
                      <div className="flex-fill text-center">
                        ${row.original.price}
                      </div>
                      <div className="flex-fill text-center">
                        <Button
                          color="primary"
                          onClick={() => this.buy(row.original)}
                        >
                          <span
                            className="d-flex align-items-center"
                            style={{ gap: "0.5rem" }}
                          >
                            <IoIosCard />
                            {buyText}
                          </span>
                        </Button>
                      </div>
                    </div>
                  ),
                },
              ]}
              data={optionsList}
              showPagination={false}
              pageSize={optionsList.length}
              className="-striped -highlight my-4"
              TheadComponent={() => null} // Hides the table header
            />
          ) : (
            <Spinner className="my-4" color="primary" />
          )}
        </CardBody>
      </div>
    );
  }
}

export default withTranslation()(ScannerBuyContent);
