import { useEffect, useState } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Spinner } from "reactstrap";

import {
  getApiSettings,
  getChartReport,
  getRemainingCredits,
} from "API/ScannerAPI";
import "assets/layouts.css";
import { withTranslation } from "react-i18next";

// Map your timeRange to numeric days
const periodMap = {
  "24h": 1,
  "7d": 7,
  "30d": 30,
  "12m": 365,
};

// Plugin to draw "Valid X" in the center of the donut
const centerTextPlugin = {
  id: "centerTextPlugin",
  afterDraw(chart) {
    const {
      ctx,
      chartArea: { width, height, left, top },
    } = chart;
    let validValue = 0;
    const ds = chart.data.datasets?.[0];
    if (ds && ds.data && ds.data.length > 0) {
      validValue = ds.data[0] || 0; // first data point is "Valid"
    }
    const centerX = left + width / 2;
    const centerY = top + height / 2;

    ctx.save();
    ctx.font = "bold 22px sans-serif";
    ctx.fillStyle = "black";
    ctx.textAlign = "center";
    ctx.fillText(`Valid ${validValue}`, centerX, centerY);
    ctx.restore();
  },
};

const ScannerApiDashboard = ({ t }) => {
  // Box stats
  const [persistentCredits, setPersistentCredits] = useState(0);
  const [dailyQuota, setDailyQuota] = useState(0);
  const [remainingQuota, setRemainingQuota] = useState(0);

  // Chart states
  const [loading, setLoading] = useState(false);
  const [timeRange, setTimeRange] = useState("24h");
  const [apiKeys, setApiKeys] = useState([]);
  const [selectedApiKey, setSelectedApiKey] = useState(""); // "" => sum all
  const [lineData, setLineData] = useState(null);
  const [donutData, setDonutData] = useState(null);

  // 1) On mount => fetch keys + credits
  useEffect(() => {
    async function fetchInitial() {
      setLoading(true);
      try {
        // 1) Get API keys
        const respSettings = await getApiSettings();
        const keys = respSettings.data || [];
        setApiKeys(keys);

        // 2) Get remaining credits
        // According to your new structure:
        // {
        //   "message": "Success",
        //   "cod": "200",
        //   "remaing_persistent": 0,
        //   "remaing_subscription": 0,
        //   "credits_subscription": 0
        // }
        const creditsResp = await getRemainingCredits();
        // Use the correct fields from the new structure:
        setPersistentCredits(creditsResp.remaing_persistent || 0);
        setDailyQuota(creditsResp.credits_subscription || 0);
        setRemainingQuota(creditsResp.remaing_subscription || 0);
      } catch (err) {
        console.error("Error fetching initial data:", err);
      } finally {
        setLoading(false);
      }
    }
    fetchInitial();
  }, []);

  // 2) On timeRange or selectedApiKey => fetch chart
  useEffect(() => {
    if (!apiKeys.length) return;

    async function fetchChart() {
      setLoading(true);
      try {
        const days = periodMap[timeRange] || 1;

        if (!selectedApiKey) {
          // Summation of all keys
          let combinedLineDetails = [];
          let combinedDonut = { valid: 0, invalid: 0, unknown: 0 };

          for (const k of apiKeys) {
            const keyName = k.apiname || k.name;
            const resp = await getChartReport(days, keyName);
            const data = resp.data || {};

            // line => chartDataDetails => sum
            if (data.chartDataDetails) {
              combinedLineDetails = sumLineDetails(
                combinedLineDetails,
                data.chartDataDetails
              );
            }

            // donut => chartBreakdownData => sum
            if (data.chartBreakdownData) {
              data.chartBreakdownData.forEach((item) => {
                const labelLower = item.label.toLowerCase();
                if (labelLower === "valid") {
                  combinedDonut.valid += item.value || 0;
                } else if (labelLower === "invalid") {
                  combinedDonut.invalid += item.value || 0;
                } else if (labelLower === "unknown") {
                  combinedDonut.unknown += item.value || 0;
                }
              });
            }
          }

          setLineData(buildLineData(combinedLineDetails, t));
          setDonutData(buildDonutData(combinedDonut, t));
        } else {
          // Single key
          const resp = await getChartReport(days, selectedApiKey);
          const data = resp.data || {};

          // line
          const details = data.chartDataDetails || [];
          setLineData(buildLineData(details, t));

          // donut
          if (data.chartBreakdownData) {
            let donutObj = { valid: 0, invalid: 0, unknown: 0 };
            data.chartBreakdownData.forEach((item) => {
              const labelLower = item.label.toLowerCase();
              if (labelLower === "valid") {
                donutObj.valid += item.value || 0;
              } else if (labelLower === "invalid") {
                donutObj.invalid += item.value || 0;
              } else if (labelLower === "unknown") {
                donutObj.unknown += item.value || 0;
              }
            });
            setDonutData(buildDonutData(donutObj, t));
          } else {
            setDonutData(null);
          }
        }
      } catch (err) {
        console.error("Error fetching chart data:", err);
        setLineData(null);
        setDonutData(null);
      } finally {
        setLoading(false);
      }
    }

    fetchChart();
  }, [timeRange, selectedApiKey, apiKeys, t]);

  // Build line data => 3 lines: Valid=legitimate, Invalid=phishing, Unknown=unknown
  function buildLineData(details, t) {
    // details => e.g. [ {y: "Mar 2025", legitimate, phishing, unknown, ...}, ...]
    if (!details.length) {
      return null; // no data => show "No line data"
    }
    const labels = details.map((d) => d.y);
    const validData = details.map((d) => d.legitimate || 0);
    const invalidData = details.map((d) => d.phishing || 0);
    const unknownData = details.map((d) => d.unknown || 0);

    return {
      labels,
      datasets: [
        {
          label: t("scanner_api.dashboard.valid"),
          data: validData,
          borderColor: "green",
          backgroundColor: "rgba(0,255,0,0.2)",
          fill: true,
        },
        {
          label: t("scanner_api.dashboard.invalid"),
          data: invalidData,
          borderColor: "red",
          backgroundColor: "rgba(255,0,0,0.2)",
          fill: true,
        },
        {
          label: t("scanner_api.dashboard.unknown"),
          data: unknownData,
          borderColor: "#ff9800",
          backgroundColor: "rgba(255,152,0,0.2)",
          fill: true,
        },
      ],
    };
  }

  // Summation => if baseArr is empty => just copy newArr
  // else match by "y"
  function sumLineDetails(baseArr, newArr) {
    if (!baseArr.length) {
      return JSON.parse(JSON.stringify(newArr));
    }
    const result = JSON.parse(JSON.stringify(baseArr));
    for (const item of newArr) {
      const existing = result.find((r) => r.y === item.y);
      if (existing) {
        existing.legitimate =
          (existing.legitimate || 0) + (item.legitimate || 0);
        existing.phishing = (existing.phishing || 0) + (item.phishing || 0);
        existing.unknown = (existing.unknown || 0) + (item.unknown || 0);
        existing.total = (existing.total || 0) + (item.total || 0);
      } else {
        result.push(JSON.parse(JSON.stringify(item)));
      }
    }
    return result;
  }

  // Build donut data from {valid, invalid, unknown}
  // If all are zero => return null => big "0"
  function buildDonutData(obj, t) {
    const sum = obj.valid + obj.invalid + obj.unknown;
    if (sum === 0) {
      return null; // triggers the big "0"
    }
    return {
      labels: [
        t("scanner_api.dashboard.valid"),
        t("scanner_api.dashboard.invalid"),
        t("scanner_api.dashboard.unknown"),
      ],
      datasets: [
        {
          data: [obj.valid, obj.invalid, obj.unknown],
          backgroundColor: ["#76a142", "#f44336", "#ff9800"],
          hoverBackgroundColor: ["#5b7e31", "#d32f2f", "#ed6c02"],
          borderWidth: 1,
        },
      ],
    };
  }

  // line chart options => no negative or fraction
  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
          },
        },
      ],
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (ctx) => {
            const datasetLabel = ctx.dataset.label || "";
            const value = ctx.parsed.y;
            return `${datasetLabel}: ${value}`;
          },
        },
      },
      legend: { display: false },
    },
  };

  // donut => center text plugin
  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "60%",
    plugins: {
      legend: { position: "bottom" },
    },
  };

  return (
    <CSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <div className="scanner-api-dashboard-container">
        {/* TOP: Stats row */}
        <div className="stats-row">
          <div className="stat-box blue">
            <i
              className="pe-7s-shield"
              style={{ marginRight: 8, fontSize: "1.2rem" }}
            />
            <div className="stat-value">{persistentCredits}</div>
            <div className="stat-label">
              {t("scanner_api.dashboard.persistent_credits")}
            </div>
          </div>
          <div className="stat-box green">
            <i
              className="pe-7s-wallet"
              style={{ marginRight: 8, fontSize: "1.2rem" }}
            />
            <div className="stat-value">{dailyQuota}</div>
            <div className="stat-label">
              {t("scanner_api.dashboard.daily_quota_label")}
            </div>
          </div>
          <div className="stat-box yellow">
            <i
              className="pe-7s-cloud"
              style={{ marginRight: 8, fontSize: "1.2rem" }}
            />
            <div className="stat-value">{remainingQuota}</div>
            <div className="stat-label">
              {t("scanner_api.dashboard.remaining_quota_label")}
            </div>
          </div>
        </div>

        {/* Middle: Key selector + time-range tabs */}
        <div
          className="time-range-row"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            marginBottom: "1rem",
          }}
        >
          <div className="api-key-selector">
            <label htmlFor="apiKeySelect" style={{ marginRight: 6 }}>
              {t("scanner_api.dashboard.select_api_key")}:
            </label>
            <select
              id="apiKeySelect"
              value={selectedApiKey}
              onChange={(e) => setSelectedApiKey(e.target.value)}
            >
              <option value="">
                {t("scanner_api.dashboard.select_option")}
              </option>
              {apiKeys.map((k, idx) => (
                <option key={idx} value={k.apiname || k.name}>
                  {k.apiname || k.name}
                </option>
              ))}
            </select>
          </div>

          <div
            className="time-range-tabs"
            style={{ display: "flex", gap: "1rem" }}
          >
            <button
              onClick={() => setTimeRange("24h")}
              className={timeRange === "24h" ? "active" : ""}
            >
              {t("scanner_api.dashboard.last_24_hours")}
            </button>
            <button
              onClick={() => setTimeRange("7d")}
              className={timeRange === "7d" ? "active" : ""}
            >
              {t("scanner_api.dashboard.last_7_days")}
            </button>
            <button
              onClick={() => setTimeRange("30d")}
              className={timeRange === "30d" ? "active" : ""}
            >
              {t("scanner_api.dashboard.last_30_days")}
            </button>
            <button
              onClick={() => setTimeRange("12m")}
              className={timeRange === "12m" ? "active" : ""}
            >
              {t("scanner_api.dashboard.last_12_months")}
            </button>
          </div>
        </div>

        {/* Bottom: line + donut charts */}
        <div className="charts-row" style={{ display: "flex", gap: "1rem" }}>
          {loading ? (
            <div
              style={{ textAlign: "center", width: "100%", marginTop: "2rem" }}
            >
              <Spinner color="primary" />
            </div>
          ) : (
            <>
              <div className="chart-container line-chart">
                {lineData && lineData.labels?.length ? (
                  <Line
                    data={lineData}
                    options={lineChartOptions}
                    height={260}
                  />
                ) : (
                  <div style={{ textAlign: "center", marginTop: "2rem" }}>
                    <h5>No line data</h5>
                  </div>
                )}
              </div>
              <div className="chart-container doughnut-charts">
                {donutData ? (
                  <Doughnut
                    data={donutData}
                    options={doughnutChartOptions}
                    plugins={[centerTextPlugin]}
                    height={260}
                  />
                ) : (
                  <div style={{ textAlign: "center", marginTop: "2rem" }}>
                    <h1>0</h1>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </CSSTransitionGroup>
  );
};

export default withTranslation()(ScannerApiDashboard);
