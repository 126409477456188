export const NavItems = [
  // {
  //   icon: 'pe-7s-server',
  //   label: 'Manage Services',
  //   to: '/admin/services',
  // },
  {
    icon: 'pe-7s-cloud',
    label: 'Manage Organizations',
    to: '/admin/organizations',
  },
  {
    icon: 'pe-7s-cash',
    label: 'Payments',
    to: '/admin/paymentsreport',
  },
  {
    icon: 'pe-7s-mail',
    label: 'Phishing Test',
    to: '/admin/phishingtest',
  },
  {
    icon: 'pe-7s-speaker',
    label: 'Campaigns',
    content: [
      {
        label: 'Manage Campaigns',
        to: '/admin/campaigns',
      },
      {
        label: 'Visualize Campaigns',
        to: '/admin/campaignsvisualize',
      },
      {
        label: 'Campaign Targets',
        to: '/admin/campaigntargets',
      }
    ]
  },
  {
    icon: 'pe-7s-lock',
    label: 'DLP Templates',
    content: [
      {
        label: 'RegExp Templates',
        to: '/admin/dlpregexes',
      },
      // {
      //   label: 'Generic Policies',
      //   to: '/admin/dlppolicies',
      // }
    ]
  },
  {
    icon: 'pe-7s-settings',
    label: 'Config',
    content: [
      {
        label: 'Manage Admins',
        to: '/admin/admins',
      },
      {
        label: 'Messages',
        to: '/admin/messages',
      },
      {
        label: 'Blog',
        to: '/admin/blog',
      },
      {
        label: 'Ads',
        to: '/admin/ads',
      },
      {
        label: 'Gateways',
        to: '/admin/gateways',
      },
      {
        label: 'Outlook Accounts',
        to: '/admin/outlookaccounts',
      },
    ]
  },
  {
    icon: 'pe-7s-global',
    label: 'Partners',
    to: '/admin/partners',
  },
  {
    icon: 'pe-7s-global',
    label: 'Reported URLs',
    to: '/admin/reported',
  },
  {
    icon: 'pe-7s-settings',
    label: 'Catalog',
    content: [
      {
        label: 'Threats',
        to: '/admin/threats',
      },
      {
        label: 'Category',
        to: '/admin/category',        
      },
    ]
  },
  {
    icon: 'pe-7s-global',
    label: 'Statistics',
    to: '/admin/statistics',
  },
  {
    icon: 'pe-7s-settings',
    label: 'Payments - Stripe',
    content: [
      {
        label: 'Subscriptions',
        to: '/admin/stripesubs',
      },
      {
        label: 'Payment',
        to: '/admin/stripepayment',        
      },
    ]
  },
  {
    icon: 'pe-7s-global',
    label: 'History',
    to: '/admin/history',
  }
];