import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card, CardBody, FormGroup, Label, Input, Button } from 'reactstrap';
import ReactTable from "react-table";
import { toast } from 'react-toastify';
import matchSorter from 'match-sorter'
import { withTranslation } from "react-i18next";

import PageTitle from 'Layout/Components/PageTitle';
import { DropdownList, Multiselect } from 'react-widgets';
import NumericInput from 'react-numeric-input';
import { visualizeCampaigns } from 'API/ConsoleServicesAPI';
import VisualizeResult from './VizualizeResult';
import { CampaignContext } from '../CampaignContext';
import FeaturesHelper from './FeaturesHelper';
//
class VisualizeCampaigns extends React.Component {

  static contextType = CampaignContext;

  constructor(props) {
    super(props);

    this.state = {
      features_data: [],
      features_values: [],
      feature_types_data: ['categorical', 'numerical', 'set'],
      feature_types_values: '',
      tokenize: false,
      threshold: 0,
      result: null
    };

    FeaturesHelper.loadFeatures(); // preload features

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeType = this.handleChangeType.bind(this);
    this.handleSelectCampaign = this.handleSelectCampaign.bind(this);
    this.visualize = this.visualize.bind(this);
  }

  handleChange(event) {
    const obj = event.target || event;
    const value = obj.type === 'checkbox'
      ? obj.checked : obj.value;
    this.setState({
      [obj.name]: value
    })
  }

  handleChangeType(value) {
    FeaturesHelper.getFeatures(value).then(features => {
      console.log('Using features', features);
      this.setState({
        'feature_types_values': value,
        'features_data': features,
        'features_values': []
      })
    }).catch(error => {
      toast["error"](this.props.t('campaign.messages.no_features'));
    });
  }

  handleSelectCampaign(event, prop) {
    this.context.selectCampaign(prop.index, event.target.checked);
  }

  visualize() {
    const campaigns = this.context.campaigns;
    const { features_values, feature_types_values, tokenize, threshold } = this.state;
    const campaign_keys = [];
    campaigns.forEach(c => {
      if (c.select) {
        campaign_keys.push(c.Key)
      }
    });
    if (campaign_keys.length === 0) {
      toast["error"](this.props.t('campaign.messages.no_campaign_selected'));
      return;
    }
    if (!feature_types_values) {
      toast["error"](this.props.t('messages.fill_all_fields'));
    }
    visualizeCampaigns(features_values, feature_types_values, campaign_keys,
      tokenize, feature_types_values === 'set' ? threshold : '').then(result => {
        this.setState({
          result: result
        });
      }).catch(error => {
        toast["error"](this.props.t('errors.loading', { element: 'Campaign', message: error.message }));
      });
  }

  render() {
    const { t, i18n } = this.props;

    const resultModal = this.state.result &&
      <VisualizeResult open={true} result={this.state.result}
        onClose={() => this.setState({ result: null })} />;

    return (
      <Fragment>
        {resultModal}
        <PageTitle
          heading={t("campaigns.actions.visualize")}
          icon="pe-7s-glasses icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="">
                <CardBody>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="feature_types_values" >{t('campaigns.feature_type')}</Label>
                        <DropdownList
                          id="feature_types_values"
                          data={this.state.feature_types_data}
                          value={this.state.feature_types_values}
                          onChange={(val) => this.handleChangeType(val)} />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="features_values" >{t('campaigns.features')}</Label>
                        <Multiselect
                          data={this.state.features_data}
                          value={this.state.features_values}
                          allowCreate={false}
                          onChange={value => this.handleChange({ name: "features_values", value: value })}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for="threshold" >{t('campaigns.threshold')}</Label>
                        <NumericInput id="threshold" className="form-control"
                          value={this.state.threshold} min={0.0} max={1.0} step={0.1}
                          onChange={num => this.handleChange({ name: "threshold", value: num })}
                          disabled={this.state.feature_types_values !== 'set'} />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup check>
                        <Input type="checkbox" name="tokenize" id="tokenize"
                          checked={this.state.tokenize} onChange={this.handleChange} />
                        <Label for="tokenize" >{t('campaigns.tokenize')}</Label>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup className='custom-form-button'>
                        <Button color="primary" onClick={this.visualize}>{t('campaigns.actions.visualize')}</Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    columns={[
                      {
                        Header: '',
                        accessor: 'select',
                        width: 50,
                        Cell: (prop) => (
                          <div className="d-block w-100 text-center">
                            <Input type="checkbox"
                              checked={this.context.campaigns[prop.index][prop.column.id]}
                              onChange={(e) => this.handleSelectCampaign(e, prop)} />
                          </div>
                        )
                      },
                      {
                        Header: t("campaigns.id"),
                        accessor: 'Key'
                      },
                      {
                        Header: t("campaigns.description"),
                        accessor: 'Description',
                        id: 'Description',
                        filterMethod: (filter, rows) => {
                          const result = matchSorter(rows, filter.value, {
                            keys: [
                              "Description"
                            ], threshold: matchSorter.rankings.WORD_STARTS_WITH
                          });
                          return result;
                        },
                        filterAll: true
                      }
                    ]}
                    data={this.context.campaigns}
                    defaultPageSize={10}
                    filterable={false}
                    filtered={this.state.filtered}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withRouter(withTranslation()(VisualizeCampaigns))