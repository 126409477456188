import React from "react";
import { withTranslation } from "react-i18next";
import { Button, Card, CardBody, Input } from "reactstrap";

class ScannerApiUrlVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      urlToVerify: "",
      verificationResult: null,
    };
  }

  handleVerifyUrl = async () => {
    if (!this.state.urlToVerify.trim()) return;
    // Mock response: randomly mark as valid or invalid
    const isValid = Math.random() > 0.5;
    const mockResponse = {
      url: this.state.urlToVerify,
      domain: "example.com",
      status: isValid ? "valid" : "invalid",
      reason: isValid ? "Safe domain" : "Rejected domain",
      ipAddress: "123.45.67.89",
      isMalicious: !isValid,
    };
    this.setState({ verificationResult: mockResponse });
  };

  render() {
    const { t } = this.props;
    const { verificationResult } = this.state;
    const isResultValid =
      verificationResult && verificationResult.status === "valid";
    const bannerColor = isResultValid ? "#28a745" : "#dc3545";
    const bannerText = isResultValid
      ? t("scanner_api.url_verification.valid_banner")
      : t("scanner_api.url_verification.invalid_banner");

    return (
      <div className="scanner-url-verification-container">
        <div className="verification-header">
          <h4>{t("scanner_api.url_verification.header")}</h4>
        </div>
        <Card className="mt-3">
          <CardBody>
            <div className="input-row">
              <Input
                type="text"
                placeholder={t(
                  "scanner_api.url_verification.input_placeholder"
                )}
                value={this.state.urlToVerify}
                onChange={(e) => this.setState({ urlToVerify: e.target.value })}
              />
              <Button color="success" onClick={this.handleVerifyUrl}>
                {t("scanner_api.url_verification.verify_button")}
              </Button>
            </div>
          </CardBody>
        </Card>
        {this.state.verificationResult && (
          <Card className="mt-3">
            <div
              className="verification-status-banner"
              style={{ backgroundColor: bannerColor }}
            >
              <h5 className="mb-0 text-white">{bannerText}</h5>
            </div>
            <CardBody>
              <h6>{t("scanner_api.url_verification.result_title")}</h6>
              <table className="verification-details">
                <tbody>
                  {Object.entries(this.state.verificationResult).map(
                    ([key, value]) => (
                      <tr key={key}>
                        <td className="field-name">{key}</td>
                        <td className="field-value">{String(value)}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default withTranslation()(ScannerApiUrlVerification);
