import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Route, useLocation } from 'react-router-dom';
import Dashboard from './Dashboard';
import AppSelection from './AppSelection'
import { Organization } from './Organizations';
import Departments from './Departments';
import Accounts from './Accounts';
import AddInConfig from './AddInConfig';
import MSConfigs from './ProviderSettings';
import {
  DomainProximity, MessageOrigin, CampaignDetails,
} from './ThreatIntelligence';
import {
  CampaignAnalysis, RelevantAnalysis, SimilarCampaign, CampaignProximity
} from './ThreatHunting';
import {
  Blacklist, RegularExpressions, Whitelist
} from './ThreatMitigation';
import AccountsBlacklist, { AccountBlacklist } from "./AccountBlacklist"
import { Policies, ComplianceViolations } from './DLP';
import { Domains } from './Domains';
import { TargetCampaigns, VisualizeCampaignsCtx } from './Campaigns';
import { UserReport } from './Reports';
import { Billing, PaymentsReport } from './Payments';
import Incidents, { IncidentDetail } from './Incidents';
import EmailAnalysis from './EmailAnalysis';
import {
  LandingPage, EmailTemplate, UsersAndGroups,
  SMTPConfig, Campaign, PhishingDashboard, 
  PhishingCampaignDetails, Simulations
} from './Phishing'
import {ScannerApiDashboard, ScannerApiBuy, ScannerApiSubscriptions, ScannerApiPayments, StripeBuy,
  ScannerApiSettings,
  ScannerApiUrlVerification} from './ScannerApi';
import Header from '../Layout/Header';
import AppSidebar from '../Layout/AppSidebar';
import ThemeOptions from '../Layout/ThemeOptions';
import AppBreadcrumb from '../Layout/AppBreadcrumb';
import { AuthContext } from 'context/AuthContext';
import consoleAPI from 'API/Console/ConsoleAPI';
import { Servers } from './EmailServers';
import QuarantineList, { QuarantineDetail } from './Quarantine';
import ErrorBoundary from 'ErrorBoundary';
import ScannerApiDocumentation from './ScannerApi/ScannerApiDocumentation';

export default function OrganizationApp() {

  const auth = useContext(AuthContext);
  const user = auth.user;

  const { pathname } = useLocation();
  const path = pathname.substring(0, pathname.indexOf("/", 1));
  
  const [menu, setMenu] = useState([]);
  const [nav, setNav] = useState([]);

  const extractApp = ((path) => {
    let app = path.substring(path.indexOf("/", 1));
    if (app.indexOf("/", 1) > 0) { 
      app = app.substring(0, app.indexOf("/", 1));
    }
    return app;
  });
  
  useEffect(() => {
    // load navigation
    const tmpMenu = extractApp(pathname);
    if (tmpMenu !== menu) {
      consoleAPI.getNav(tmpMenu).then(result => {
        setMenu(tmpMenu);
        setNav(result);
      });
    }
  }, [pathname, menu]);
  console.log("selection", path)
  return (
    <Fragment>
      <ThemeOptions />
      <Header />
      <div className="app-main">
        <AppSidebar navigation={nav} />
        <div className="app-main__outer">
          <AppBreadcrumb />
          <div className="app-main__inner"><ErrorBoundary>
            <Route exact path={`${path}`} component={AppSelection} />
            {/* organization */}
            <Route exact path={`${path}/admin`} component={Organization} />
            <Route exact path={`${path}/admin/departments`} component={Departments} />
            <Route exact path={`${path}/admin/accounts`} component={Accounts} />
            <Route exact path={`${path}/admin/addinconfig`} component={AddInConfig} />
            <Route exact path={`${path}/admin/provider`} component={MSConfigs} />
            <Route exact path={`${path}/admin/domains`} component={Domains} />
            <Route exact path={`${path}/admin/userreport`} component={UserReport} />
            <Route exact path={`${path}/admin/billing`} component={Billing} />
            <Route exact path={`${path}/admin/payments`} render={(props) => <PaymentsReport domain={user.domain} email={user.email} {...props} />} />
            {/* phishing_detector */}
            <Route exact path={`${path}/phishing_detector`} component={Dashboard} />
            <Route exact path={`${path}/phishing_detector/domainproximity`} component={DomainProximity} />
            <Route exact path={`${path}/phishing_detector/messageorigin`} component={MessageOrigin} />
            <Route exact path={`${path}/phishing_detector/campaigndetails`} component={CampaignDetails} />
            {/* <Route exact path={`${path}/phishing_detector/emaildetails`} component={EmailDetails} /> */}
            <Route exact path={`${path}/phishing_detector/emailanalysis`} component={EmailAnalysis} />
            <Route path={`${path}/phishing_detector/campaignanalysis/:campaignId`} component={CampaignAnalysis} />
            <Route exact path={`${path}/phishing_detector/campaignanalysis`} component={CampaignAnalysis} />
            <Route exact path={`${path}/phishing_detector/relevantanalysis`} component={RelevantAnalysis} />
            <Route exact path={`${path}/phishing_detector/similarcampaign`} component={SimilarCampaign} />
            <Route exact path={`${path}/phishing_detector/campaignproximity`} component={CampaignProximity} />
            {/* <Route exact path={`${path}/phishing_detector/senderidentification`} component={SenderIdentification} /> */}
            <Route exact path={`${path}/phishing_detector/accountblacklist`} component={AccountsBlacklist} />
            <Route exact path={`${path}/phishing_detector/accountblacklist/blacklist`} component={AccountBlacklist} />
            <Route exact path={`${path}/phishing_detector/blacklist`} component={Blacklist} />
            <Route exact path={`${path}/phishing_detector/regularexpressions`} component={RegularExpressions} />
            <Route exact path={`${path}/phishing_detector/whitelist`} component={Whitelist} />
            {/* <Route exact path={`${path}/phishing_detector/settings`} component={Settings} /> */}
            {/* <Route exact path={`${path}/phishing_detector/reports`} component={Reports} /> */}
            <Route exact path={`${path}/phishing_detector/campaigntargets`} component={TargetCampaigns} />
            <Route exact path={`${path}/phishing_detector/campaignsvisualize`} component={VisualizeCampaignsCtx} />
            <Route exact path={`${path}/phishing_detector/dlppolicies`} component={Policies} />
            <Route exact path={`${path}/phishing_detector/dlpviolations`} component={ComplianceViolations} />
            <Route exact path={`${path}/phishing_detector/incidents`} component={Incidents} />
            <Route exact path={`${path}/phishing_detector/incident`} component={IncidentDetail} />
            <Route exact path={`${path}/phishing_detector/emailservers`} component={Servers} />
            <Route exact path={`${path}/phishing_detector/quarantinelist`} component={QuarantineList} />
            <Route exact path={`${path}/phishing_detector/quarantine`} component={QuarantineDetail} />
            {/* phishing_simulator */}
            <Route exact path={`${path}/phishing_simulator`} component={PhishingDashboard} />
            <Route exact path={`${path}/phishing_simulator/landingpage`} component={LandingPage} />
            <Route exact path={`${path}/phishing_simulator/emailtemplate`} component={EmailTemplate} />
            <Route exact path={`${path}/phishing_simulator/usersandgroups`} component={UsersAndGroups} />
            <Route exact path={`${path}/phishing_simulator/smtpconfig`} component={SMTPConfig} />
            <Route exact path={`${path}/phishing_simulator/phishing_campaign`} component={Campaign} />
            <Route exact path={`${path}/phishing_simulator/phishing_campaign/:id`} render={(props) => <PhishingCampaignDetails {...props} />} />
            <Route exact path={`${path}/phishing_simulator/simulations`} component={Simulations} />
            {/* scanner_api */}
            <Route exact path={`${path}/scanner_api`} component={ScannerApiDashboard} />
            <Route exact path={`${path}/scanner_api/verify`} component={ScannerApiUrlVerification} />
            <Route exact path={`${path}/scanner_api/buy`} component={ScannerApiBuy} />
            <Route exact path={`${path}/scanner_api/subscription`} component={ScannerApiSubscriptions} />
            <Route exact path={`${path}/scanner_api/payments`} component={ScannerApiPayments} />
            <Route exact path={`${path}/scanner_api/api_settings`} component={ScannerApiSettings} />
            <Route exact path={`${path}/scanner_api/documentation`} component={ScannerApiDocumentation} />
            {/* <Route exact path={`${path}/scanner_api/contact`} component={ScannerApiContact} /> */}
          </ErrorBoundary></div>
        </div>
      </div>
    </Fragment>
  )
}
