import {
  deleteApiSetting,
  getApiSettings,
  insertApiSetting,
} from "API/ScannerAPI";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";

class ScannerApiSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiName: "",
      apiKeys: [],
      loading: false,
      error: null,
    };
  }

  componentDidMount() {
    this.fetchApiKeys();
  }

  async fetchApiKeys() {
    try {
      this.setState({ loading: true, error: null });
      const data = await getApiSettings();
      this.setState({ apiKeys: data.data || [] });
    } catch (error) {
      console.error("Error fetching API keys:", error);
      this.setState({ error: error.message });
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleCreateApiKey() {
    const { apiName } = this.state;
    if (!apiName.trim()) return;
    try {
      this.setState({ loading: true, error: null });
      // Call the API to create a new key
      const result = await insertApiSetting(apiName.trim());
      // Optionally, you can check the result for errors here.
      await this.fetchApiKeys(); // Refresh the list
      this.setState({ apiName: "" });
    } catch (error) {
      console.error("Error creating API key:", error);
      this.setState({ error: error.message });
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleDeleteApiKey(apiKey) {
    try {
      this.setState({ loading: true, error: null });
      await deleteApiSetting(apiKey);
      await this.fetchApiKeys();
    } catch (error) {
      console.error("Error deleting API key:", error);
      this.setState({ error: error.message });
    } finally {
      this.setState({ loading: false });
    }
  }

  handleCopy(apiKey) {
    navigator.clipboard.writeText(apiKey);
  }

  render() {
    const { t } = this.props;
    const { apiName, apiKeys, loading, error } = this.state;

    return (
      <div className="scanner-api-settings-container">
        <h2>{t("scanner_api.settings.title")}</h2>
        {error && <div className="error-message">{error}</div>}
        <Row className="mb-3 d-flex align-items-center">
          <Col md="4">
            <Input
              type="text"
              placeholder={t("scanner_api.settings.input_placeholder")}
              value={apiName}
              onChange={(e) => this.setState({ apiName: e.target.value })}
              disabled={loading}
            />
          </Col>
          <Col md="4" >
            <Button
              color="primary"
              onClick={() => this.handleCreateApiKey()}
              disabled={loading}
            >
              {t("scanner_api.settings.create_api_key")}
            </Button>
          </Col>
        </Row>
        {loading ? (
          <Spinner className="my-4" color="primary" />
        ) : (
          <Card>
            <CardBody>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>{t("scanner_api.settings.table.name")}</th>
                    <th>{t("scanner_api.settings.table.created_at")}</th>
                    <th>{t("scanner_api.settings.table.api_key")}</th>
                    <th className="text-right py-0 align-middle">
                      {t("scanner_api.settings.table.actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {apiKeys.length > 0 ? (
                    apiKeys.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item.apiname || item.name}</td>
                        <td>{item.creationDate || item.createdAt}</td>
                        <td>
                          <Input
                            type="text"
                            readOnly
                            value={item.apikey || item.apiKey}
                          />
                        </td>
                        <td className="text-right py-0 align-middle">
                          <div className="btn-group btn-group-sm">
                            <Button
                              color="success"
                              size="sm"
                              className="mr-2"
                              onClick={() =>
                                this.handleCopy(item.apikey || item.apiKey)
                              }
                            >
                              <span
                                className="d-flex align-items-center"
                                style={{ gap: "0.5rem" }}
                              >
                                <i className="pe-7s-copy-file" />
                                {t("scanner_api.settings.table.copy")}
                              </span>
                            </Button>
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() => {
                                if (window.confirm(t("scanner_api.settings.delete_confirm", { name: item.apiname || item.name }))) {
                                  this.handleDeleteApiKey(
                                    item.apikey || item.apiKey
                                  );
                                }
                              }}
                            >
                              <span
                                className="d-flex align-items-center"
                                style={{ gap: "0.5rem" }}
                              >
                                <i className="pe-7s-trash" />
                                {t("scanner_api.settings.table.delete")}
                              </span>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        {t("scanner_api.settings.table.no_keys")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        )}
      </div>
    );
  }
}

export default withTranslation()(ScannerApiSettings);
