import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { Row, Col, Card } from 'reactstrap';
import { withTranslation } from "react-i18next";

import PageTitle from '../../Layout/Components/PageTitle';
import DashboardSelection from './DashboardSelection';
 
class DashboardPage extends React.Component {

  render() {
    const { t, i18n, location } = this.props;

    const source = location.pathname.split("/")[1];

    const title = t(`dashboards.${source}`)
    const description = t(`dashboards.${source}-description`)

    return (
      <Fragment>
        <PageTitle
          heading={title}
          subheading={description}
          icon="pe-7s-display1 icon-gradient bg-primary"
        />
        <CSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md="12">
              <Card className="card-tabs">
                <DashboardSelection />
              </Card>
            </Col>
          </Row>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default withRouter(withTranslation()(DashboardPage))
