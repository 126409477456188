import { getPaymentPlans } from "API/ScannerAPI";
import React from "react";
import { withTranslation } from "react-i18next";
import ScannerBuyContent from "./ScannerBuyContent";

class ScannerApiBuy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      plans: [],
    };
    this.init();
  }

  async init() {
    const plans = await getPaymentPlans();
    this.setState({ plans });
  }

  filterSubscription(isSubscription) {
    return this.state.plans.filter(
      (plan) => plan.isSubscription === isSubscription
    );
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <div
        className="d-flex flex-column flex-sm-row w-100"
        style={{ gap: "1rem" }}
      >
        <div className="flex-fill">
          <ScannerBuyContent
            title={t("scanner_api.buy.monthly")}
            subTitle={t("scanner_api.buy.monthly_subtitle")}
            product={t("scanner_api.buy.monthly_product")}
            tooltip={t("scanner_api.buy.monthly_tooltip")}
            buyText={t("scanner_api.buy.buy_text")}
            optionsList={this.filterSubscription(true)}
            mostPopular={t("scanner_api.buy.most_popular")}
          />
        </div>
        <div className="flex-fill">
          <ScannerBuyContent
            title={t("scanner_api.buy.pay_as_you_go")}
            subTitle={t("scanner_api.buy.pay_as_you_go_subtitle")}
            product={t("scanner_api.buy.pay_as_you_go_product")}
            tooltip={t("scanner_api.buy.pay_as_you_go_tooltip")}
            buyText={t("scanner_api.buy.buy_text")}
            optionsList={this.filterSubscription(false)}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(ScannerApiBuy);
