import { getAccessToken } from "context/AuthContext";
import User from "context/User";

const API_U_URL = process.env.REACT_APP_U_API_URL;
const STRIPE_API_URL = process.env.REACT_APP_STRIPE_API_URL;

// Get API settings (list of API keys)
export async function getApiSettings() {
  const accessToken = await getAccessToken(User.VERITAS);
  const url = `${API_U_URL}/apikey/list`;
  const response = await fetch(url, {
    mode: "cors",
    headers: {
      "content-type": "application/json",
      jwtToken: accessToken,
      email: User.email, // if available
    },
  });
  const resp = await response.json();
  console.log("getApiSettings:", resp);
  return resp;
}

// Create a new API key with the given name
export async function insertApiSetting(apiName) {
  const accessToken = await getAccessToken(User.VERITAS);
  const url = `${API_U_URL}/apikey/create/${apiName}`;
  const response = await fetch(url, {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      jwtToken: accessToken,
      Origin: "http://127.0.0.1:3000",
      email: User.email,
    },
  });
  const resp = await response.json();
  console.log("insertApiSetting:", resp);
  return resp;
}

// Delete an API key
export async function deleteApiSetting(apiKey) {
  const accessToken = await getAccessToken(User.VERITAS);
  const url = `${API_U_URL}/apikey/delete/${apiKey}`;
  const response = await fetch(url, {
    mode: "cors",
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      jwtToken: accessToken,
      Origin: "http://127.0.0.1:3000",
      email: User.email,
    },
  });
  console.log("deleteApiSetting response:", response);
  return response.body;
}

// Get all payments
export async function getPaymentList() {
  const accessToken = await getAccessToken(User.VERITAS);
  const url = `${API_U_URL}/payment/list`;
  const response = await fetch(url, {
    mode: "cors",
    headers: {
      "content-type": "application/json",
      jwtToken: accessToken,
      email: User.email,
    },
  });
  const recs = await response.json();
  console.log("getPaymentList:", recs);
  return recs;
}

// Get subscription info
export async function getSubscription() {
  const accessToken = await getAccessToken(User.VERITAS);
  const url = `${API_U_URL}/payment/subscription`;
  const response = await fetch(url, {
    mode: "cors",
    headers: {
      "content-type": "application/json",
      jwtToken: accessToken,
      email: User.email,
    },
  });
  const resp = await response.json();
  console.log("getSubscription:", resp);
  return resp;
}

// Get remaining credits
export async function getRemainingCredits() {
  const accessToken = await getAccessToken(User.VERITAS);
  const url = `${API_U_URL}/payment/credits`;
  const response = await fetch(url, {
    mode: "cors",
    headers: {
      "content-type": "application/json",
      jwtToken: accessToken,
      email: User.email,
    },
  });
  const resp = await response.json();
  console.log("getRemainingCredits:", resp);
  return resp;
}

// Get chart report for a specific period and API name (if needed)
export async function getChartReport(period, apiName) {
  const accessToken = await getAccessToken(User.VERITAS);
  const url = `${API_U_URL}/user/chart?period=${period}&apiname=${encodeURIComponent(
    apiName
  )}`;
  const response = await fetch(url, {
    mode: "cors",
    headers: {
      "content-type": "application/json",
      jwtToken: accessToken,
      email: User.email,
    },
  });
  const resp = await response.json();
  console.log("getChartReport:", resp);
  return resp;
}


export async function getUserOverview() {
  const accessToken = await getAccessToken(User.VERITAS);
  const url = `${API_U_URL}/user/overview`;
  const response = await fetch(url, {
    mode: "cors",
    headers: {
      "content-type": "application/json",
      jwtToken: accessToken,
      email: User.email,
    },
  });
  const resp = await response.json();
  console.log("getUserOverview:", resp);
  return resp;
}

export async function freeSubscription() {
  const accessToken = await getAccessToken(User.VERITAS);
  const url = `${API_U_URL}/user/free_subscription`;
  const response = await fetch(url, {
    mode: "cors",
    headers: {
      "content-type": "application/json",
      jwtToken: accessToken,
      email: User.email,
    },
  });
  const resp = await response.json();
  console.log("freeSubscription:", resp);
  return resp;
}


// Verify URL using the endpoint described in the documentation
export async function verifyUrl(urlToVerify, apiKey) {
  const accessToken = await getAccessToken(User.VERITAS);
  const url = `${API_U_URL}/url/lookup`;
  const urls = [urlToVerify];
  const response = await fetch(url, {
    mode: "cors",
    method: "POST",
    headers: {
      "content-type": "application/json",
      "accept": "application/json",
      "apiKey": apiKey,
    },
    body: JSON.stringify({ urls }),
  });
  const resp = await response.json();
  console.log("verifyUrl:", resp);
  return resp;
}

// Get payment plans (Stripe products)
export async function getPaymentPlans() {
  const accessToken = await getAccessToken(User.VERITAS);
  const url = `${STRIPE_API_URL}/products`;
  const response = await fetch(url, {
    mode: "cors",
    headers: {
      "content-type": "application/json",
      jwtToken: accessToken,
      email: User.email,
    },
  });
  const recs = await response.json();
  console.log("getPaymentPlans:", recs);
  return recs;
}

// Create a Stripe payment
export async function createStripePayment(params) {
  const accessToken = await getAccessToken(User.VERITAS);
  const url = `${STRIPE_API_URL}/create`;
  const response = await fetch(url, {
    mode: "cors",
    method: "POST",
    headers: {
      "content-type": "application/json",
      jwtToken: accessToken,
      email: User.email,
    },
    body: JSON.stringify(params),
  });
  const resp = await response.json();
  return resp;
}
